import Vue from 'vue'
import axios from "axios";

export default {
    namespaced: true,
    state: {
        vehicles: [],
        page: 1,
        perPage: 15,
        sortBy: 'id',
        sort: 'desc',
        filter:{},
        selectedUser:'',
    },
    mutations: {
        selectUser(state, user){
            state.selectedUser = user
        },
        setVehicles(state, vehicles) {
            state.vehicles = vehicles
        },
        setFilter(state, filter){
            state.filter = filter
        },
        setPage(state, page) {
            state.page = page
        },
        setPerPage(state, perPage) {
            if(perPage === '9999'){
                state.page = 1
            }
            state.perPage = perPage
        },
        setSorting(state, payload) {
            state.sort = payload.sort
            state.sortBy = payload.sortBy
        },
        unset(state) {
            state.vehicles = []
            state.page = 1
            state.perPage = 15
            state.sortBy = 'id'
            state.sort = 'desc'
            state.filter = {}
            state.selectedUser = ''
        },
        destroy(state){
            state.vehicles = []
            state.page = 1
            state.perPage = 15
            state.sortBy = 'id'
            state.sort = 'desc'
            state.filter = {}
            state.selectedUser = ''
        }
    },
    actions: {
        getVehicles({state, rootState, commit}) {
            let link = 'https://declarant.by/rest/user/vehicles'
            if (state.selectedUser  && state.selectedUser.length) {
                if (rootState.auth.user.groups.includes('21')) {
                    link = link + `/${state.selectedUser}`
                }
            }
            axios.post( link, {
                page: state.page,
                perPage: state.perPage,
                sortBy: state.sortBy,
                sort: state.sort,
                filter: state.filter
            }).then(res => {
                commit('setVehicles', res.data.data)
            })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
        addVehicle({dispatch}, payload) {
            axios.post('https://declarant.by/rest/user/vehicles/add', payload)
                .then(res => {
                    Vue.notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    dispatch('getVehicles')
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
        removeVehicle({dispatch}, payload) {
            axios.post('https://declarant.by/rest/user/vehicles/remove', payload)
                .then(res => {
                    Vue.notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    dispatch('getVehicles')
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },

    },
    getters: {
        vehicles: s => s.vehicles,
        page: s => s.page,
        perPage: s => s.perPage
    }
}
