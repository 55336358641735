import axios from "axios";

export default {
    namespaced: true,
    state: {
        perPage: 15,
        page: 1,
        items: [],
        query: '',
    },
    actions: {
        fetchItems({commit, state}, payload) {
            payload ? commit('setQuery', payload) : ''
            let data = {
                query: payload ? payload : state.query,
                page: state.page,
                perPage: state.perPage
            }
            axios.post('https://declarant.by/rest/search', data)
                .then(res => {
                    commit('setItems', res.data.data)
                })
        }
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        setPage(state, payload) {
            state.page = payload
        },
        setPerPage(state, payload) {
            state.perPage = payload
        },
        setQuery(state, payload) {
            state.query = payload
        },
        resetState(state) {
            state.page = 1
            state.perPage = 15
            state.items = []
        }
    },
    getters: {
        items: s => s.items,
        page: s => s.page,
        perPage: s => s.perPage
    },

}