export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function checkLocaleCookie() {
    if (!getCookie('locale')) {
        let expires = 60 * 60 * 24 * 30
        document.cookie = 'locale=ru;path=/;expires=' + expires + ';max-age=' + expires
    }
}
