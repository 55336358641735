import moment from "moment";
export  default  function checkSessionLastUpdate(){
    let ls = window.localStorage
    let vuex = ls.getItem('vuex')
    if(vuex){
        vuex = JSON.parse(vuex)
        if(Object.prototype.hasOwnProperty.call(vuex, 'auth')){
            let diff = moment.duration(moment().diff(moment(vuex.auth.updated,'DD.MM.YYYY HH:mm:ss')))
            if(diff.asMinutes() > 60){
                ls.removeItem('vuex')
            }
        }

    }
}