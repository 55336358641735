export default {
    namespaced: true,
    state: {
        perPage: 15,
        page: 1,
        category: ''
    },
    mutations: {
        setPage(state, payload) {
            state.page = payload
        },
        setPerPage(state, payload) {
            state.perPage = payload
        },
        setCategory(state, payload) {
            state.category = payload
        }
    },
    getters: {
        perPage: s => s.perPage,
        page: s => s.page,
        category: s => s.category
    }
}