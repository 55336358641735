import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import IdleVue from 'idle-vue'
import VTooltip from 'v-tooltip'
import i18n from './i18n'
import Notifications from "vue-notification";
import VueMeta from 'vue-meta'
import './index.css'
import interceptorsSetupAxios from './helpers/interceptorsAxios'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@webcomponents/webcomponentsjs'
import 'loading-attribute-polyfill'


interceptorsSetupAxios()

Vue.config.productionTip = false
Vue.use(VueMeta, {
    keyName:'meta',
    refreshOnceOnNavigation: true
})
Vue.use(Notifications)
Vue.use(VTooltip)
Vue.use(VueRouter, Vuex, i18n)

const eventsHub = new Vue()
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1200000
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')




