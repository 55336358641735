export default function ListenMutations(store) {
    store.subscribe((mutation) => {
        if (mutation.type === 'search/setPage' || mutation.type === 'search/setPerPage') {
            store.dispatch('search/fetchItems')
        }
        if (mutation.type === 'clientCars/setPage' || mutation.type === 'clientCars/setPerPage' ||
            mutation.type === 'clientCars/setSorting' || mutation.type === 'clientCars/setFilter') {
            store.dispatch('clientCars/getVehicles')
        }
        if (mutation.type === 'admin/setPage' || mutation.type === 'admin/setPerPage' ||
            mutation.type === 'admin/setSorting' || mutation.type === 'admin/unset' || mutation.type === 'admin/setFilter') {
            store.dispatch('admin/getContracts')
        }
        if (mutation.type === 'clientTrans/setPage' || mutation.type === 'clientTrans/setPerPage' ||
            mutation.type === 'clientTrans/setSorting' || mutation.type === 'clientTrans/setFilter') {
            store.dispatch('clientTrans/fetch')
        }
        if (mutation.type === 'clientRegistrations/setPage' || mutation.type === 'clientRegistrations/setPerPage' ||
            mutation.type === 'clientRegistrations/setSorting' || mutation.type === 'clientRegistrations/setFilter') {
            store.dispatch('clientRegistrations/fetch')
        }
        if (mutation.type === 'history/setPage' || mutation.type === 'history/setPerPage' ||
            mutation.type === 'history/setSorting' || mutation.type === 'history/setFilter') {
            store.dispatch('history/fetch')
        }
    })
}