import axios from "axios";

export default {
    namespaced: true,
    state: {
        page: 1,
        perPage: 15,
        sortBy: 'id',
        sort: 'desc',
        selectedUser:'',
        registrations: [],
        loading:false,
        filter:{}
    },
    actions: {
        fetch({commit,rootState, state}) {
            commit('setLoading', true)
            let link = 'https://declarant.by/rest/user/client/registrations'
            if (state.selectedUser && state.selectedUser.length) {
                if (rootState.auth.user.groups.includes('21')) {
                    link = link + `/${state.selectedUser}`
                }
            }
            return axios.post(link, {
                page: state.page,
                perPage: state.perPage,
                sortBy: state.sortBy,
                sort: state.sort,
                filter:state.filter
            }).then(res => {
                commit('setRegistrations', res.data.data)
                commit('setLoading', false)
            })
                .catch(err => {
                    commit('setLoading', false)
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
    },
    mutations: {
        selectUser(state, user){
            state.selectedUser = user
        },
        setFilter(state, params){
            state.filter = params
        },
        setLoading(state, status){
            if(status){
                return state.loading = status
            }
            state.loading = !state.loading
        },
        setRegistrations(state, items) {
            state.registrations = items
        },
        setPage(state, page) {
            state.page = page
        },
        setPerPage(state, perPage) {
            if(perPage === '9999'){
                state.page = 1
            }
            state.perPage = perPage
        },
        setSorting(state, payload) {
            state.sort = payload.sort
            state.sortBy = payload.sortBy
        },
        unset(state) {
            state.page = 1
            state.perPage = 15
            state.sortBy = ''
            state.sort = 'desc'
            state.registrations = []
            state.selectedUser = ''
            state.loading = false
            state.filter = {}
        },
        destroy(state) {
            state.page = 1
            state.perPage = 15
            state.sortBy = ''
            state.sort = 'desc'
            state.registrations = []
            state.selectedUser = ''
            state.loading = false
            state.filter = {}
        },
    },
    getters: {
        registrations: s => s.registrations,
        page: s => s.page,
        perPage: s => s.perPage,
        loading: s => s.loading
    }
}