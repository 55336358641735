export default [
    // {
    //     name: 'Услуги',
    //     link: '/',
    //
    // },
    // {
    //     name: 'Маркировка',
    //     link: '/',
    //
    // },
    {
        name: 'Программные продукты',
        link: '/software',

    },
    {
        name: 'Обновления ПО',
        link: '/software/updates',
    },
    {
        name: 'Регистрация в ЗО',
        link: 'https://belarusborder.by/',
        outer:true
    },
    {
        name: 'Tax Free',
        link: 'https://www.tax-free.by/',
        outer:true
    },
    {
        name: 'О компании',
        link: '/',
    },
    {
        name: 'Новости и события',
        link: '/news',
    },
    {
        name: 'Вакансии',
        link: '/career',
    },
    // {
    //     name: 'Контакты',
    //     link: '/',
    // },
    // {
    //     name: 'Обращения',
    //     link: '',
    // },
]
