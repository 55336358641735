import Vue from "vue";

import axios from "axios";

export default {
    namespaced: true,
    state: {
        contracts: [],
        exceeded:[],
        requests:[],
        group: 4,
        page: 1,
        perPage: 15,
        sort: 'desc',
        sortBy: 'id',
        filter: {},
        contractsSimple: []
    },
    mutations: {
        setContracts(state, contracts) {
            state.contracts = contracts
        },
        setExceeded(state, exceeded){
            state.exceeded = exceeded
        },
        setRequests(state, items){
            state.requests = items
        },
        //id,name,unp, contract
        setContractsSimple(state, contracts) {
            state.contractsSimple = contracts
        },
        setFilter(state, filter) {
            state.filter = filter
        },
        setError() {
            return true
        },
        setPage(state, page) {
            state.page = page
        },
        setPerPage(state, perPage) {
            state.perPage = perPage
        },
        setSorting(state, payload) {
            state.sort = payload.sort
            state.sortBy = payload.sortBy
        },
        unset(state) {
            state.vehicles = []
            state.page = 1
            state.perPage = 15
            state.sortBy = 'id'
            state.sort = 'desc'
            state.filter = {}
        },
        destroy(state) {
            state.vehicles = []
            state.page = 1
            state.perPage = 15
            state.sortBy = 'id'
            state.sort = 'desc'
            state.filter = {}
        }
    },
    actions: {
        sendLoginPassword(context, payload) {
            return axios.post('https://declarant.by/rest/user/contract/sendLoginPassword', payload)
        },
        getContractSingle(context, payload) {
            return axios.post('https://declarant.by/rest/user/contract/single', payload)

        },
        async updateContract({dispatch}, payload) {
            await axios.post('https://declarant.by/rest/user/contract/update', payload)
                .then(res => {
                    Vue.notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    dispatch('getContracts')
                }).catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
        getContracts({commit, state}) {
            axios.post('https://declarant.by/rest/user/contract/all', {
                group: state.group,
                page: state.page,
                perPage: state.perPage,
                sortBy: state.sortBy,
                sort: state.sort,
                filter: state.filter
            }).then(res => {
                commit('setContracts', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        getContractsAll({commit}) {
            axios.post('https://declarant.by/rest/user/contract/all', {
                group: 4,
                page: 1,
                perPage: 9999,
            }).then(res => {
                commit('setContracts', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        getContractsAllSimple({commit}) {
            axios.post('https://declarant.by/rest/user/contract/simple', {
                group: 4,
                page: 1,
                perPage: 9999,
            }).then(res => {
                commit('setContractsSimple', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        checkIfUserExist(context, login) {
            return axios.post('https://declarant.by/rest/user/checkLogin', {login: login})
        },
        async getBalanceByUnp(context, unp) {
            return axios.get(`https://declarant.by/rest/user/client/balance/${unp}`).then(res => {
                res.data.data
                return res
            }).catch(err => {
                return Promise.reject(err)
            })

        },
        getExceeded({commit}) {
            return axios.post('https://declarant.by/rest/user/contract/exceeded')
                .then(res => {
                    commit('setExceeded', res.data.data)
                })
                    .catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
                return Promise.reject(err)
            })
        },
        createAdditionalContract({dispatch}, payload) {
            return axios.post('https://declarant.by/rest/user/contract/additional/create', payload).then(res => {
                dispatch('getExceeded');
                return res
            })
        },
        getContractsRequests({commit, state}) {
            axios.post('https://declarant.by/rest/user/contract/all', {
                group: 1,
                page: state.page,
                perPage: state.perPage,
                sortBy: state.sortBy,
                sort: state.sort,
                filter: state.filter
            }).then(res => {
                commit('setRequests', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        resolveContractRequest({dispatch}, payload){
            return axios.post('https://declarant.by/rest/user/contract/resolve', payload).then(res => {
                dispatch('getContractsRequests');
                return res
            })
        }
    },
    getters: {
        contracts: s => s.contracts,
        exceeded: s => s.exceeded,
        requests: s => s.requests,
        group: s => s.group,
        page: s => s.page,
        perPage: s => s.perPage,
        contractsSimple: s => s.contractsSimple
    }
}
