export  default [
    {
        path: 'register-company',
        name: 'RegisterAsCompany',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/RegisterAsCompany.vue')
    },
    {
        path: 'attach-documents',
        name: 'AttachDocuments',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/AttachDocs.vue')
    },
    {
        path: 'contracts',
        name: 'Contracts',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/Contracts.vue')
    },
    {
        path: 'contracts/:id',
        name: 'ContractDetail',
        props: true,
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/ContractDetail.vue')
    },
    {
        path: 'contracts/edit/:id',
        name: 'ContractEdit',
        props: true,
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/ContractEdit.vue')
    },
    {
        path: 'cars/:id',
        props: true,
        name: 'ClientCarsAdmin',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/Cars.vue')
    },
    {
        path: 'transportations/:id',
        props: true,
        name: 'TransportationsAdmin',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/Transportations.vue')
    },
    {
        path: 'registrations/:id',
        props: true,
        name: 'ClientRegistrationsAdmin',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/Registrations.vue')
    },
    {
        path: 'payments/:id',
        props: true,
        name: 'ClientPaymentsAdmin',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/Payments.vue')
    },
    {
        path: 'export-clients',
        name: 'ExportClients',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/ExportClients.vue')
    },
    {
        path: 'manage-payments',
        name: 'ManagePayments',
        meta: {
            groups: ['21']
        },
        component: () => import('@/views/user/admin/ManagePayments.vue')
    },
    {
        path: 'history',
        name: 'History',
        meta: {
            groups: ['22']
        },
        component: () => import('@/views/user/admin/History.vue')
    },
]