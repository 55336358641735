<template>
    <div class="sm:mb-5">
        <h5 class="mb-2">Подпишись</h5>
        <small class="text-green-300" v-if="message">{{ message }}</small>
        <div class="sm:block lg:flex">
            <div class="sm:w-full lg:w-3/4">
                <input v-model="email" type="text" class="sm:w-full focus:outline-none"
                       placeholder="Введите ваш E-mail">
            </div>
            <div class="sm:w-full  lg:w-1/3">
                <button @click.prevent="subscribe" class="bg-blue-400 sm:w-full sm:mx-0 lg:mx-2   rounded-sm py-3">
                    <img class="mx-auto" src="@/assets/img/social/plane.svg" alt="">
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "News.vue",
    data: () => ({
        email: '',
        message: ''
    }),
    methods: {
        subscribe() {

            axios.post('https://declarant.by/rest/subscribe/update', {
                email: this.email,
                rubrics: [1]
            }).then(res => {
                this.message = res.data.data
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
            }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        }
    }
}
</script>

<style scoped>
input[type="text"] {
    padding: 14px 15px;
    background-color: #494949;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
}
</style>