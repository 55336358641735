export default {
    state: {
        lang: 'ru',
        darkTheme:true,
    },
    mutations: {
        setLocale(state, payload) {
            let expires = 60 * 60 * 24 * 30
            document.cookie = 'locale=' + payload + ';path=/;expires=' + expires + ';max-age=' + expires
            state.lang = payload
        },
        setTheme(state){
            state.darkTheme = !state.darkTheme
            if(state.darkTheme === true){
                document.documentElement.classList.add('dark')
            }else {
                document.documentElement.classList.remove('dark')
            }
        }
    },
    getters: {
        lang: s => s.lang,
        darkTheme: s => s.darkTheme
    },
}