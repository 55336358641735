import admin from "./admin";
import client from "./client";
export default {
    path: '/user/dashboard/',
    meta: {
        requiresAuth: true
    },
    component: () => import('@/views/user/Dashboard.vue'),
    children: [
        {
            path: '/',
            name: 'Home',
            component: () => import('@/views/user/Home.vue')
        },
        {
            path: 'user-edit',
            name: 'UserEdit',
            component: () => import('@/views/user/UserEdit.vue')
        },
        {
            path: 'transport-declaration',
            name: 'TransportDeclaration',
            component: () => import('@/views/user/transportDeclaration/Index.vue')
        },
        {
            path: 'transport-declaration/application',
            name: 'TransportDeclarationApplication',
            component: () => import('@/views/user/transportDeclaration/Application.vue')
        },
        {
            path: 'transport-declaration/:id/show',
            name: 'TransportDeclarationDetail',
            component: () => import('@/views/user/transportDeclaration/ApplicationDetail.vue')
        },
        {
            path: 'transport-declaration/:id/edit',
            name: 'TransportDeclarationEdit',
            component: () => import('@/views/user/transportDeclaration/ApplicationEdit.vue')
        },
        {
            path: 'transport-declaration/:id/payment',
            name: 'TransportDeclarationPayment',
            component: () => import('@/views/user/transportDeclaration/ApplicationPayment.vue')
        },
        ...admin,
        ...client
    ]
}