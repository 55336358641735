<template>
    <div>
        <div class="sidebar ">
            <router-link tag="a" to="/" class="logo">
                <img src="@/assets/img/logo/main.svg" alt="Белтаможсервис">
            </router-link>
            <ul>

                <li v-for="(item, idx) in menu" :key="'item'+idx" class="side-btn flex"  @click="select(idx)">
                    <a v-if="item.outer" :href="item.link" target="_blank" rel="nofollow">{{ item.name }}</a>
                    <router-link v-else :to="item.link">{{ item.name }}</router-link>
                    <img v-show="item.children && item.children.length" src="@/assets/img/icons/rightarr.svg" alt="Белтаможсервис">
                </li>

            </ul>
        </div>

        <div class="sidebar2" v-if="selected !== ''">
            <button class="grey-btn text-gray-300 inline-flex items-center" id="hideSide2" @click="hide2">
                <img src="@/assets/img/icons/leftarr.svg" alt="Белтаможсервис">
                Назад
            </button>
            <ul>
                <li class="side-btn-2 flex" v-for="(el,idx) in menu[selected].children" :key="'slide-'+idx" @click="select2(idx)">
                    <a v-if="el.outer" :href="el.link" target="_blank" rel="nofollow">{{ el.name }}</a>
                    <router-link v-else :to="el.link">{{el.name}}</router-link>
                    <img v-if="el.children && el.children.length" src="@/assets/img/icons/rightarr.svg" alt="Белтаможсервис">
                </li>
            </ul>
        </div>

        <div class="sidebar3">
            <button class="grey-btn text-gray-300 inline-flex items-center" id="hideSide3" @click="hide3">
                <img src="@/assets/img/icons/leftarr.svg" alt="Белтаможсервис">
                Назад
            </button>
            <ul v-if="menu[selected].children[selected2]">
                <li  v-for="(item,idx) in menu[selected].children[selected2].children" :key="'slide-2-'+idx">
                    <a v-if="item.outer" :href="item.link" target="_blank" rel="nofollow">{{ item.name }}</a>
                    <router-link v-else :to="item.link">{{item.name}}</router-link>
                </li>

            </ul>
        </div>


        <nav class="fixed w-full z-40 top-0 bg-gray-800">
            <div class=" mx-auto px-2 sm:px-1 header-padding">
                <div class="relative flex items-center justify-center h-16">

                    <div class="flex-1 flex  md:justify-between sm:items-center sm:justify-between">
                        <div class="flex-shrink-0 flex items-center">
                            <router-link tag="a" to="/">
                                <img class="block h-8 w-auto lg:block sm:hidden" src="@/assets/img/logo/main.svg"
                                     alt="Белтаможсервис">
                                <img class="block h-8 w-auto sm:block lg:hidden" src="@/assets/img/logo/main-small.svg"
                                     alt="Белтаможсервис">
                            </router-link>
                        </div>
                        <div class="sm:block sm:ml-6" v-if="menu">
                            <ul class="sm:hidden xl:flex  space-x-3">
                                <li class="drop-btn drop-btn-top text-white" v-for="(item, idx) in menu" :key="idx">
                                    <a v-if="item.outer" class="py-7 w-full drop-btn-top " :href="item.link" target="_blank" rel="nofollow">{{
                                            item.name
                                        }}</a>
                                    <router-link v-else tag="a" class="py-7 w-full drop-btn-top " :to="item.link">{{
                                            item.name
                                        }}
                                    </router-link>
                                    <div class="drop-box" v-if="item.children && item.children.length">
                                        <ul>
                                            <li class="drop-btn-2 " v-for="(child, idx) in item.children"
                                                :key="'child'+idx">
                                                <a v-if="child.outer" class="p-6 w-full whitespace-nowrap" :href="chil.link"
                                                   target="_blank" rel="nofollow">{{ child.name }}</a>
                                                <router-link v-else tag="a" class="p-6 w-full whitespace-nowrap" :to="child.link">
                                                    {{ child.name }}
                                                </router-link>

                                                <ul class="drop-box-2 "
                                                    v-if="child.children && child.children.length">
                                                    <li class="drop-btn-3 w-full mx-2" v-for="(el, idx) in child.children"
                                                        :key="'child2'+idx">
                                                        <a v-if="el.outer" class="w-full  whitespace-nowrap" :href="el.link" target="_blank" rel="nofollow">{{
                                                                el.name
                                                            }}</a>
                                                        <router-link v-else tag="a" class="w-full whitespace-nowrap" :to="el.link">{{
                                                                el.name
                                                            }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="side-nav sm:hidden md:flex">

                            <ThemeSwitcher></ThemeSwitcher>

                            <router-link tag="a" to="/search" href="" class="search-btn">
                                <img src="@/assets/img/icons/search.svg" alt="Белтаможсервис">
                            </router-link>
                            <button class="tel flex items-center">
                                <img src="@/assets/img/icons/tel.svg" alt="Белтаможсервис">
                                <a href="tel:197">197</a>
                                <div class="tel-drop">
                                    <p class="text-white text-sm">Международный номер</p>
                                    <a href="tel:+375 33 911 11 97">+375 33 911 11 97</a>
                                    <br><br>
                                    <p>
                                        <a href="tel:+375 33 911 11 97" class="flex">
                                            <img src="@/assets/img/icons/tg.svg" alt="Белтаможсервис">
                                            +375 33 911 11 97
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:+375 33 911 11 97" class="flex">
                                            <img src="@/assets/img/icons/wh.svg" alt="Белтаможсервис">
                                            +375 33 911 11 97
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:+375 33 911 11 97" class="flex">
                                            <img src="@/assets/img/icons/vb.svg" alt="Белтаможсервис">
                                            +375 33 911 11 97
                                        </a>
                                    </p>
                                </div>
                            </button>

                            <LocaleSwitcher></LocaleSwitcher>
                            <LoginButton></LoginButton>

                        </div>
                        <div class="burger sm:block xl:hidden">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>

            </div>


        </nav>
    </div>

</template>

<script>
import LocaleSwitcher from './LocaleSwitcher'
import ThemeSwitcher from './ThemeSwitcher'
import LoginButton from './LoginButton'
import Menu from "../../menu/menu";

export default {
    components: {
        LocaleSwitcher, ThemeSwitcher, LoginButton
    },
    name: "Header",
    data: () => ({
        menu: [],
        selected: 0,
        selected2: 0
    }),
    methods:{
        select(idx){
            this.selected = idx
            if(this.menu[this.selected].children && this.menu[this.selected].children.length){
                this.open2()
            }
        },
        select2(idx){
            this.selected2 = idx
            if(this.menu[this.selected].children[this.selected2].children &&
                this.menu[this.selected].children[this.selected2].children.length){
                this.open3()
            }
        },
        open2(){
            let sidebar2 = document.querySelector('.sidebar2');
            sidebar2.classList.toggle('sidebar2-ac')
        },
        open3(){
            let sidebar3 = document.querySelector('.sidebar3');
            sidebar3.classList.toggle('sidebar3-ac')
        },
        hide2(){
            let sidebar2 = document.querySelector('.sidebar2');
            sidebar2.classList.remove('sidebar2-ac')
        },
        hide3(){
            let sidebar3 = document.querySelector('.sidebar3');
            sidebar3.classList.remove('sidebar3-ac')
        }
    },
    created() {
        this.menu = Menu
    },
    mounted() {
        let bugger = document.querySelector('.burger');
        let sidebar = document.querySelector('.sidebar');
        let sidebar2 = document.querySelector('.sidebar2');
        let sidebar3 = document.querySelector('.sidebar3');
        bugger.addEventListener('click', function () {

            if (sidebar.classList.contains('sidebar-ac')) {
                sidebar.classList.remove('sidebar-ac')
            } else {
                sidebar.classList.add('sidebar-ac')
            }

            bugger.classList.toggle('burger-ac')
            if (!bugger.classList.contains('burger-ac')) {
                sidebar2.classList.remove('sidebar2-ac')
            }
            if (!bugger.classList.contains('burger-ac')) {
                sidebar3.classList.remove('sidebar3-ac')
            }

        })
    }
}
</script>
<style scoped>
* {
    box-sizing: border-box;
}
@media (min-width: 1000px) and (max-width: 1499px){
    .header-padding{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media (min-width: 1500px){
    .header-padding{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.burger {
    height: 45px;
    width: 45px;
    border-radius: 10px;
    background-color: #262626;
    position: relative;
}

.burger span:first-child {
    width: 30px;
    height: 1px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    left: 8px;
    transition: all .3s ease;
    top: 13px;
}

.burger span {
    width: 30px;
    height: 1px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 8px;
    top: 23px;
    transition: all .3s ease;
}

.burger span:last-child {
    width: 30px;
    height: 1px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 8px;
    top: 33px;
    transition: all .3s ease;
}

.burger-ac span {
    opacity: 0;
}

.burger-ac span:first-child {
    opacity: 1;
    transform: rotate(-135deg);
    top: 23px;
}

.burger-ac span:last-child {
    opacity: 1;
    transform: rotate(135deg);
    top: 23px;
}

.sidebar {
    transition: all .3s ease;
    padding: 40px 0 40px 0px;
    overflow: scroll;
    position: fixed;
    width: 300px;
    left: -400px;
    top: 0;
    height: 100%;
    background-color: #333;
    z-index: 110;
    box-shadow: 0px 11px 14px 2px rgba(0, 0, 0, 0.23);
}

.sidebar-ac {
    left: 0;
}

.sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 35px;
}

.sidebar li {
    font-size: 18px;
    padding: 15px 15px;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
}

.sidebar a {
    color: #fff;
}

.sidebar .drop-btn img {
    margin-left: 5px;
}

.sidebar .drop-box {
    position: relative;
    padding: 0;
    margin: 15px 0;
    display: none;
}

.sidebar .drop-box li {
    margin: 0;
    font-size: 16px;
}

.drop-box-vis .drop-box {
    display: block !important;
}

.sidebar .drop-btn:hover .drop-box {
    display: none;
}

.sidebar .logo {
    padding-left: 15px;
}

/* sidebar 2 */


.sidebar2 {
    transition: all .3s ease;
    padding: 40px 0 40px 0px;
    overflow: scroll;
    position: fixed;
    width: 300px;
    left: -400px;
    top: 0;
    height: 100%;
    background-color: #333;
    z-index: 120;
    box-shadow: 0px 11px 14px 2px rgba(0, 0, 0, 0.23);
}

.sidebar2-ac {
    left: 0;
}

.sidebar2 ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 35px;
}

.sidebar2 li {
    font-size: 18px;
    padding: 15px 15px;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
}

.sidebar2 a {
    color: #fff;
}

.sidebar2 .drop-btn img {
    margin-left: 5px;
}

.sidebar2 .drop-box {
    position: relative;
    padding: 0;
    margin: 15px 0;
    display: none;
}

.sidebar2 .drop-box li {
    margin: 0;
    font-size: 16px;
}

.drop-box-vis .drop-box {
    display: block !important;
}

.sidebar2 .drop-btn:hover .drop-box {
    display: none;
}

.sidebar2 .logo {
    padding-left: 15px;
}

.side-btn img {
    margin-left: 10px;
}

.side-btn-2 img {
    margin-left: 10px;
}


/* sidebar 3 */


.sidebar3 {
    transition: all .3s ease;
    padding: 40px 0 40px 0px;
    overflow: scroll;
    position: fixed;
    width: 300px;
    left: -400px;
    top: 0;
    height: 100%;
    background-color: #333;
    z-index: 120;
    box-shadow: 0px 11px 14px 2px rgba(0, 0, 0, 0.23);
}

.sidebar3-ac {
    left: 0;
}

.sidebar3 ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 35px;
}

.sidebar3 li {
    font-size: 18px;
    padding: 15px 15px;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
}

.sidebar3 a {
    color: #fff;
}

.sidebar3 .drop-box {
    position: relative;
    padding: 0;
    margin: 15px 0;
    display: none;
}


.drop-btn {
    position: relative;
}

.drop-box li {
    margin: 0;
    font-size: 16px;
}

.drop-box-vis .drop-box {
    display: block !important;
}

.drop-btn:hover .drop-box {
    display: none;
}

.drop-box {
    display: none;
    position: absolute;
    padding: 20px 0px;
    z-index: 20;
    width: auto;
    min-width: 250px;
    left: 0px;
    margin: 20px 0;
    top: 100%;
    background-color: #333333;
}

.drop-box ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.drop-box li, .drop-box a {
    padding: 10px;
    transition: all .3s ease;
    /* border-radius: 10px; */
    width: 100%;
}

.drop-box li:hover {
    background-color: #424141;
}

.drop-btn:hover .drop-box {
    display: block;
}


.drop-btn-2 {
    position: relative;
    margin-right: 0 !important;
}

.drop-box-2 {
    display: none;
    position: absolute;
    padding: 0px;
    z-index: 20;
    width: auto;
    min-width: 200px;
    left: 100%;
    top: 0px;
    background-color: #333333;
}

.drop-btn-2:hover .drop-box-2 {
    display: block;
}

.drop-box-2 li, .drop-box-2 a {
    padding: 10px;
    transition: all .3s ease;
    width: 100%;
}

.drop-btn-3 {
    position: relative;
    margin-right: 0 !important;
}

.drop-box-3 {
    display: none;
    position: absolute;
    padding: 20px 0;
    z-index: 20;
    min-width: 200px;
    width: 100%;
    left: 100%;
    top: 0px;
    background-color: #333333;
}

.drop-btn-3:hover .drop-box-3 {
    display: block;
}

.drop-box-3 li, .drop-box-3 a {
    padding: 10px;
    transition: all .3s ease;
    border-radius: 0px;
    width: 100%;
}

.search-btn{
    transition: all .3s ease;
    border-radius: 10px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-btn:hover, .profile:hover {
    background-color: #424141;
}

.search-btn img, .profile img {
    height: 15px;
}

.side-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tel a, .lang span {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.tel img, .lang img {
    margin-right: 5px;
    margin-top: -2px;
}

.tel, .lang {
    padding: 12px;
    border-radius: 9px;
    transition: all .3s ease;
    position: relative;
    background-color: transparent;
    border: none;
}

.tel:hover, .lang:hover {
    background-color: #424141;
}

.tel-drop {
    display: none;
    background-color: #333333;
    width: 220px;
    position: absolute;
    top: 100%;
    text-align: left;
    right: 0;
    padding: 25px 20px 10px 20px;
}

.tel:hover .tel-drop {
    display: block;
}

.tel-drop a {
    position: relative;
}

.white-txt {
    color: #fff;
    font-size: 12px;
    margin-bottom: 5px;
}

.lang-drop {
    display: none;
    background-color: #333333;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 25px 0px 0px 0px;
    text-align: center;
    width: 100%;
}

.lang:hover .lang-drop {
    display: block;
}

.lang-drop p, .lang-drop button {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 10px 0px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 0;
}

.lang-drop p:hover, .lang-drop button {
    background-color: #494949;
}

.side-nav.mobile .tel-drop {
    left: -60px;
}

.sidebar::-webkit-scrollbar,
.sidebar2::-webkit-scrollbar,
.sidebar3::-webkit-scrollbar {
    background: transparent;
}

.grey-btn {
    font-size: 16px;
    padding: 12px 25px;
    margin-left: 15px;
    border-radius: 6px;
    background-color: #F4F4F4;
    font-weight: 700;
    color: #333;
    border: none;
    transition: all .3s ease;;
}

.grey-btn:hover {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 1550px){
    .drop-btn-top {
        font-size: 12px!important;
    }

}
</style>
