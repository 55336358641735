export default [
    {
        path: 'client',
        name: 'Client',
        component: () => import('@/views/user/ClientPage.vue'),

    },
    {
        path: 'cars',
        name: 'Client-cars',
        component: () => import('@/views/user/client/Cars.vue')
    },
    {
        path: 'transportations',
        name: 'Transportations',
        component: () => import('@/views/user/client/Transportations.vue')
    },
    {
        path: 'payments',
        name: 'ClientPayments',
        component: () => import('@/views/user/client/Payments.vue')
    },
    {
        path: 'registrations',
        name: 'ClientRegistrations',
        component: () => import('@/views/user/client/Registrations.vue')
    },
    {
        path: 'export',
        name: 'ExportData',
        component: () => import('@/views/user/ExportData.vue')
    },
]