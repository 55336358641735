import Vue from 'vue'
import axios from "axios";

export default {
    namespaced: true,
    state: {
        items: [],
        page: 1,
        perPage: 15,
        sortBy: 'id',
        sort: 'desc',
        filter:{},
    },
    mutations: {
        setItems(state, items){
          state.items = items
        },
        setFilter(state, filter){
            state.filter = filter
        },
        setPage(state, page) {
            state.page = page
        },
        setPerPage(state, perPage) {
            if(perPage === '9999'){
                state.page = 1
            }
            state.perPage = perPage
        },
        setSorting(state, payload) {
            state.sort = payload.sort
            state.sortBy = payload.sortBy
        },
        unset(state) {
            state.items = []
            state.page = 1
            state.perPage = 15
            state.sortBy = 'id'
            state.sort = 'desc'
            state.filter = {}

        }
    },
    actions: {
        fetch({state, commit}) {
            let link = 'https://declarant.by/rest/user/history/get'

            axios.post( link, {
                page: state.page,
                perPage: state.perPage,
                sortBy: state.sortBy,
                sort: state.sort,
                filter: state.filter
            }).then(res => {
                commit('setItems', res.data.data)
            })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },


    },
    getters: {
        items: s => s.items,
        page: s => s.page,
        perPage: s => s.perPage
    }
}
