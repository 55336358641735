import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        payments: [],
        unresolved: [],
        selectedUser: ''
    },
    mutations: {
        setSelectedUser(state, user) {
            state.selectedUser = user
        },
        setUnresolved(state, items) {
            state.unresolved = items
        },
        setPayments(state, items) {
            state.payments = items
        },
        unset(state) {
            state.payments = []
            state.unresolved = []
            state.selectedUser = ''
        },
        destroy(state) {
            state.payments = []
            state.unresolved = []
            state.selectedUser = ''
        }
    },
    actions: {
        getPaymentsAsAdmin({commit, state, rootState}) {
            if (!rootState.auth.user.groups.includes('21')) {
                Vue.notify({
                    title: 'Ошибка',
                    text: 'Недостаточно прав',
                    type: 'error'
                })
            }
            axios.get(`https://declarant.by/rest/user/client/payments/${state.selectedUser}`).then(res => {
                commit('setPayments', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        getPayments({commit}) {
            axios.get('https://declarant.by/rest/user/client/payments').then(res => {
                commit('setPayments', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        getUnresolved({commit}) {
            axios.get('https://declarant.by/rest/user/client/unresolvedPayments').then(res => {
                commit('setUnresolved', res.data.data)
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        updateUnresolved(context, payload) {
            return axios.post('https://declarant.by/rest/user/client/unresolvedPayments/update', payload)
        },

        updateClientPayment(context, payload) {
            return axios.post('https://declarant.by/rest/user/client/payments/update', payload)
        },

        getUnusedPayments(context, payload){
            return axios.post('https://declarant.by/rest/user/client/unusedPayments', payload)
        },

        cancelUnusedPayment(context, payload){
            return axios.post('https://declarant.by/rest/user/client/unusedPayments/cancel', payload)
        },

        searchPayment(context, payload){
            return axios.post('https://declarant.by/rest/user/client/unusedPayments/search', payload)
        }

    },
    getters: {
        payments: s => s.payments,
        unresolved: s => s.unresolved
    }
}