export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/user/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/user/Register.vue')
    },
    {
        path: '/register/client',
        name: 'RegisterClient',
        component: () => import('../views/user/client/Register.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/user/ResetPassword.vue')
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('../views/user/ChangePassword.vue')
    },
    {
        path: '/user-confirm',
        name: 'ConfirmRegistration',
        component: () => import('../views/user/ConfirmRegistration.vue')
    },
]