import axios from "axios";
export default {
    namespaced:true,
    state:{
        items:[],
        types:[],
        filials:[]
    },
    mutations:{
        setData(state, payload){
            state.items =  payload.items
            state.types =  payload.types
            state.filials =  payload.filials
        },
    },
    actions:{
        fetchItems({commit}) {
            axios.get('https://declarant.by/rest/career')
                .then(res => {
                    commit('setData', res.data.data)
                })
        }
    },
    getters:{
        items: s => s.items,
        filials: s => s.filials,
        types:s => s.types
    }

}