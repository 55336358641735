<template>
  <div class="locale-changer">
    <select class="uppercase text-white px-3 border-0 bg-gray-800 font-semibold focus:outline-none" ref="locale"
            @change="setCookie($event.target.value)" v-model="$i18n.locale">
      <option class="uppercase" v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  data: () => ({
    languages: ['ru', 'en', 'by']
  }),
  methods: {
    setCookie(locale) {
      this.$store.commit('setLocale', locale)
    }
  }
}
</script>

<style scoped>
select {
  -webkit-appearance: none;
}
</style>