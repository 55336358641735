<template>
    <router-link to="/user/dashboard" class="user profile relative hover:bg-gray-600 text-white " :class="{'bg-blue-300':token}">
        <img class="sm:visible lg:hidden" src="@/assets/img/icons/profile.svg" alt="Белтаможсервис">
        <span class="sm:hidden lg:block">Личный кабинет</span>
        <ul v-if="online" class="user-menu absolute -bottom-9 dark:bg-gray-700 bg-gray-200 dark:text-white px-5 py-2 right-0">
            <li @click="submit" class="dark:hover:text-green-300 hover:text-blue-700">
                Выйти
            </li>
        </ul>
    </router-link>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "LoginButton",
    computed:{
        online(){
          return !! this.token.length
        },
        ...mapGetters({token:'auth/token'})
    },
    methods:{
         submit(){
             this.$store.dispatch('auth/logout')
        }
    }
}
</script>

<style scoped>
.profile {
    transition: all .3s ease;
    border-radius: 10px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
 .profile img {
    height: 15px;
}
 .user-menu{
     visibility: hidden;
 }
.user:hover .user-menu{
    visibility: visible;
}
@media  screen and (max-width: 450px) {
  .profile{
    width: 45px;
    padding: 0;
  }
}

</style>