import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import software from "./software";
import authorization from "./auth";
import dashboard from "./dashboard/index";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        meta: {
            title: 'РУП Белтаможсервис'
        },
        component: () => import('../views/Index.vue')
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue')
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('../views/company/News.vue')
    },
    {
        path: '/news/:code',
        name: 'NewsDetail',
        props: true,
        component: () => import('../views/company/NewsDetail.vue')
    },
    {
        path: '/career',
        name: 'Career',
        component: () => import('../views/company/Career.vue')
    },
    {
        path: '/career/:code',
        name: 'CareerDetail',
        props: true,
        component: () => import('../views/company/CareerDetail.vue')
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import('../views/Support.vue')
    },
    {
        path: '/subscribe/confirm',
        name: 'SubscribeConfirm',
        props: true,
        component: () => import('../views/SubscribeConfirm.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/SearchPage.vue')
    },
    {

        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/company/Contacts.vue')
    },
    // {
    //     path: '/directors',
    //     component: () => import('../views/company/Directors.vue')
    // },
    {
        path: '/structure',
        component: () => import('../views/company/Structure.vue')
    },
    {
        path: '/about-company/branches/:code',
        props: true,
        name: 'Branches',
        component: () => import('../views/about/Branches.vue')

    },
    // {
    //     path: '/about-company/directors',
    //     name: 'Directors',
    //     component: () => import('../views/company/Directors.vue')
    // },
    // {
    //     path: '/about-company/structure',
    //     name: 'Structure',
    //     component: () => import('../views/company/Structure.vue')
    // },
    // {
    //     path: '/appeals/schedule/',
    //     name: 'AppealsSchedule',
    //     component: () => import('../views/appeals/Schedule.vue')
    //
    // },
    // {
    //     path: '/payments/eseal/',
    //     name: 'PaymentEseal',
    //     component: () => import('../views/eseal/Payform.vue')
    // },

    ...software,
    ...authorization,
    dashboard


]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0}
        }
    }
})


router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["auth/token"]) {
            if (to.meta.groups && to.meta.groups.length) {
                let userGroups = store.getters["auth/user"].groups
                if (userGroups.includes(...to.meta.groups)) {
                    next()
                    return
                }else {
                    next('/user/dashboard')
                }
            }

            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
