import axios from "axios";
export default{
    namespaced:true,
    state:{
        countries:[],
    },
    mutations:{
      setCountries(state, countries){
          state.countries = countries
      }
    },
    actions:{
        getCountries({commit}){
            axios.get('https://declarant.by/rest/catalogs/countries').then(res =>{
                commit('setCountries', res.data.data)
            })
        }
    },
    getters:{
        countries:s => s.countries
    }

}