export default [
    {
        path: '/software',
        name: 'Software',
        component: () => import('../views/software/Software.vue')
    },
    {
        path: '/software/form',
        name: 'SoftwareForm',
        component: () => import('../views/software/SoftwareForm.vue')
    },
    {
        path: '/software/products/:code',
        name: 'SoftwareProductsDetail',
        component: () => import('../views/software/SoftwareProductsDetail.vue')
    },
    {
        path: '/software/manual/:code',
        name: 'SoftwareProductsManual',
        component: () => import('../views/software/SoftwareProductsManual.vue')
    },
    {
        path: '/software/updates/client/:code',
        name: 'SoftwareProductsUpdateClient',
        component: () => import('../views/software/SoftwareProductsUpdateClient.vue')
    },
    {
        path: '/software/updates',
        name: 'SoftwareUpdateList',
        component: () => import('../views/software/SoftwareUpdate.vue')
    },
    {
        path: '/software/updates/:code',
        name: 'SoftwareUpdateDetail',
        props: true,
        component: () => import('../views/software/SoftwareUpdateDetail.vue')
    },
]