<template>
  <div class="dark:bg-gray-800 bg-white sm:px-5 lg:px-0">
    <div class="lg:container mx-auto py-5 ">
      <div v-show="slides.length" class=" overflow-x-hidden ">
        <swiper v-if="slides.length" ref="swiperPartners" :options="swiperOptions" class="h-full">
          <swiper-slide v-for="slide in slides" :key="slide.id" class="h-28 flex justify-center">
            <a href="#" class="block h-full">
                <div class="flex items-center justify-center h-full">
                    <img loading="lazy" :src="slide.preview_picture" class="max-h-28 w-auto  tint" :alt="slide.title">
                </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>




</template>

<script>
// Swiper autoplay fix
import Vue from 'vue'
import { Swiper as SwiperClass, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'
import axios from "axios";

export default {
  name: "SwiperPartners",
  components:{
    Swiper,SwiperSlide
  },
  data:()=>({
    slides:[],
    swiperOptions: {
      autoplay:{
        delay:1000,
      },
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      // slidesPerView: 3,
      slidesPerView: 7,
      centeredSlides:true,
      speed: 400,
      loop: true,
      spaceBetween: 20,
      breakpoints: {
        280: {
          slidesPerView: 2,
          spaceBetween: 5
        },
        640: {
          slidesPerView: 3,
        },
        990: {
          slidesPerView: 5,
        },
        1200:{
          slidesPerView: 7,
          spaceBetween: 20
        }
      }
    }
  }),
  computed:{
    swiper(){
      return this.$refs.swiperPartners.$swiper
    }
  },
  created() {
    this.fetchPartners()
  },
  methods:{
    fetchPartners(){
        axios.get('https://declarant.by/rest/partners')
            .then(res => {
                this.slides = res.data.data

            })
    }
  }

}
</script>

<style scoped>
.tint{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -webkit-transition : -webkit-filter 500ms linear
}
.tint:hover{
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -webkit-transition : -webkit-filter 500ms linear
}


</style>