<template>
    <div id="app" >
        <Header></Header>
        <notifications class="mt-16"/>
        <router-view class="mt-10"></router-view>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import {checkLocaleCookie} from "./mixins/cookie";

export default {
    name: 'App',
    components: {
        Header, Footer,
    },
    beforeCreate() {
        checkLocaleCookie()
    },
    mixins: ['checkLocaleCookie']
}
</script>
<style >
::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 8px;

}
html.dark ::-webkit-scrollbar {
    background: rgb(23,23,23);
}

::-webkit-scrollbar-thumb {
    background: lightskyblue;
}
</style>