import Vue from 'vue'
import Vuex from 'vuex'
import settings from './settings'
import news from './news'
import software from "./software";
import search from "./search";
import career from "./career";
import auth from "./auth";
import catalogs from "./catalogs";
import admin from "./admin";
import history from "./history";
import clientCars from "./client/cars";
import clientTrans from "./client/transportations"
import clientRegistrations from "./client/registrations"
import clientPayments from "./client/payments"
import clientNotifications from "./client/notifications"
import checkSessionLastUpdate from "../mixins/storage";
import ListenMutations from "../plugins";
import createMutationsSharer from "vuex-shared-mutations";
import createPersistedState from "vuex-persistedstate";
import 'vue2-datepicker/locale/ru';

checkSessionLastUpdate()

const persistedState = createPersistedState({
    paths:['auth']
})

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        settings,
        admin,
        auth,
        news,
        software,
        career,
        search,
        catalogs,
        clientCars,
        clientTrans,
        clientPayments,
        clientNotifications,
        clientRegistrations,
        history
    },
    plugins: [
        ListenMutations,
        persistedState,
        createMutationsSharer({
            predicate: (mutation) => {
                const predicate = ["auth/setUser","auth/updateUser", 'auth/unsetUser'];
                // Conditionally trigger other plugins subscription event here to
                // have them called only once (in the tab where the commit happened)
                // ie. save certain values to localStorage
                // pluginStateChanged(mutation, state)
                return predicate.indexOf(mutation.type) >= 0;
            }
        })
    ]
})
