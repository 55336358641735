export default [
    // {
    //     name: 'Услуги',
    //     link: '/',
    //     children: [
    //         {
    //             name: 'Мониторинг событий',
    //             link: '/',
    //             children:[
    //                 {name: 'Этапы оформления ВПТО',link: 'https://mon.declarant.by/vpto',  outer:true},
    //                 {name: 'Этапы оформления СВХ',link: 'https://mon.declarant.by/svh',  outer:true},
    //                 {name: 'Размер очереди на ВПТО',link: 'https://mon.declarant.by/vpto',  outer:true},
    //                 {name: 'Размер очереди на РПТО',link: 'https://csd.declarant.by/rpto_places.php',  outer:true},
    //                 {name: 'Очереди в Зонах ожидания',link: 'https://mon.declarant.by/zone',  outer:true},
    //                 {name: 'Мониторинг ТС в ЗТК',link: 'https://mon.declarant.by/cars',  outer:true},
    //             ]
    //
    //         },
    //         {
    //             name: 'Оплата навигационных пломб',
    //             link: '/payments/eseal',
    //
    //         },
    //     ]
    // },
    // {
    //     name: 'Маркировка',
    //     link: '/',
    //     children: []
    // },
    {
        name: 'Программные продукты',
        link: '/software',
        children: [
            {
                name: 'Обновления ПО',
                link: '/software/updates',
            },
            {
                name: 'Заявка на приобритение ПО',
                link: '/software/form',
            },
            {
                name: 'Техническая поддержка',
                link: '/support',
            },
        ]
    },
    {
        name: 'Регистрация в ЗО',
        link: 'https://belarusborder.by/',
        outer:true
    },
    {
        name: 'Tax Free',
        link: 'https://www.tax-free.by/',
        outer:true
    },
    {
        name: 'О компании',
        link: '/',
        children: [
            {
                name: 'Новости и события',
                link: '/news',
            },
            {
                name: 'Вакансии',
                link: '/career',
            },
            // {
            //     name: 'Руководство',
            //     link: '/directors',
            // },
            // {
            //     name: 'Структура',
            //     link: '/structure',
            // },
            {
                name: 'Филиалы',
                link: '/about-company/branches/brest',
                children:[
                    {name: 'Брест',link: '/about-company/branches/brest',},
                    {name: 'Витебск',link: '/about-company/branches/vitebsk',},
                    {name: 'Гомель',link: '/about-company/branches/gomel',},
                    {name: 'Гродно',link: '/about-company/branches/grodno',},
                    {name: 'Минск',link: '/about-company/branches/minsk',},
                    {name: 'Могилев',link: '/about-company/branches/mogilev',},
                    {name: 'Белтаможиздат',link: 'http://www.beltamojizdat.by/', outer:true},
                ]

            },
        ]
    },
    {
        name: 'Контакты',
        link: '/contacts',
    },
    // {
    //     name: 'Обращения',
    //     link: '',
    //     children: [
    //         {name: 'График личного приема',link: '/appeals/schedule',},
    //     ]
    // },
]
