import axios from "axios";
import Vue from "vue";

export  default  {
    namespaced:true,
    actions:{
        acceptNotification({dispatch, rootState}){
            const id = rootState.auth.user.id || null
            if(!id) return false;
            axios.post('https://declarant.by/rest/user/client/acceptNotify', {uid:id}).then(() => {
                dispatch('auth/getUser', '', {root:true})
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        }
    }
}