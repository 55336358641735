import Vue from "vue";
import router from "../router";
import axios from "axios";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        token: '',
        user: {},
        updated:null
    },
    mutations: {
        setUser(state, payload) {
            state.token = payload.token
            state.user = payload.user
            state.updated = moment().format('DD.MM.YYYY HH:mm:ss')
        },
        updateUser(state, payload) {
            state.user = payload
            state.updated = moment().format('DD.MM.YYYY HH:mm:ss')
        },
        unsetUser(state, path) {
            let defaultLink  = '/'
            path ? defaultLink = path: false
            state.token = ''
            state.user = {}
            state.updated = null
            router.push({path: defaultLink})
        },

    },
    actions: {
        async updateUser({dispatch}, payload) {

            axios.post( "https://declarant.by/rest/user/update",payload)
                .then(res => {
                    Vue.notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    dispatch('getUser')
            }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })

        },
        getUser({commit}) {

            axios.get('https://declarant.by/rest/user/get')
                .then(res => {
                    commit('updateUser', res.data.data)
                }).catch(err => {
                Vue.notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })

        },
        login({commit}, payload) {
            axios.post('https://declarant.by/rest/user/login', payload)
                .then(res => {
                    commit('setUser', res.data.data)
                    router.push({path: '/user/dashboard'})
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        },
        logout({commit}, path) {
            axios.get('https://declarant.by/rest/user/logout')
                .then(() => {
                    commit('unsetUser', path)
                })
                .catch(err => {
                    Vue.notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })

        },
        register(context, payload) {
            return axios.post('https://declarant.by/rest/user/register', payload)
        },
        async captcha() {
            return axios.get('https://declarant.by/rest/user/captcha')

        },
        isLoginFree(context, login) {
            return axios.post('https://declarant.by/rest/user/isLoginFree', {login: login})
        },

    },
    getters: {
        token: s => s.token,
        user: s => s.user
    }
}
