import axios from 'axios';
import store from '../store'
import Vue from "vue";

export default function setup() {
    axios.interceptors.request.use(function (config) {
        const token = store.getters["auth/token"];
        const login = store.getters["auth/user"].login;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.From = login;

        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        return response
    }, function (error) {

        if (error.response.status === 401) {
            Vue.notify({
                title: 'Внимание',
                text: error.response.data.message,
                type: 'warn'
            })
            return store.dispatch('auth/logout', '/login')
        }
        return Promise.reject(error)
    })

}