<template>
    <div>
        <SwiperPartners></SwiperPartners>
        <footer class="bg-gray-800 text-gray-400 sm:px-5 sm:py-12 xl:p-12">
            <div class="mx-auto xl:container max-w-7xl ">
                <div class="sm:block gap-x-5 xl:flex">
                    <div class="sm:w-full xl:w-1/4">
                        <div class="flex">
                            <a href="/"><img src="@/assets/img/logo/logo2.svg" class="logo2" alt="Белтаможсервис"></a>
                            <a href="https://tibo.by/" target="_blank" rel="nofollow"><img src="@/assets/img/logo/tibo.png" class="logo2" alt="Белтаможсервис"></a>
                        </div>

                        <p class="small-white">
                            Таможенный представитель РБ, уполномоченный экономический оператор III типа, таможенный
                            перевозчик. Мы обеспечиваем быстрые, надежные и регулярные перевозки в страны Западной,
                            Восточной и Южной Европы, Прибалтийского региона и страны Азии.
                        </p>
                        <h5>РУП «БЕЛТАМОЖСЕРВИС» 1999-2021</h5>
                        <ul style="columns: 1;">
                            <li><a href="">Версия для слабовидящих</a></li>
                            <li><a href="">Политика конфиденциальности</a></li>
                            <li><a href="">Карта сайта</a></li>
                        </ul>
                        <div class="socials flex">
                            <a href="https://www.linkedin.com/company/%D1%80%D1%83%D0%BF-%D0%B1%D0%B5%D0%BB%D1%82%D0%B0%D0%BC%D0%BE%D0%B6%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%8120%D0%BB%D0%B5%D1%82/"
                               class="mr-2"><img src="@/assets/img/social/linkedin.png" alt="linkedin" rel="nofollow"></a>
                            <a href="https://www.instagram.com/beltamozhservice/" class="mr-2" rel="nofollow"><img
                                src="@/assets/img/social/inst.png" alt="inst" rel="nofollow"></a>
                            <a href="https://www.facebook.com/btslogistics/" class="mr-2" rel="nofollow"><img
                                src="@/assets/img/social/facebook.png" alt="facebook" rel="nofollow"></a>
                            <a href="https://www.youtube.com/channel/UC1_RJPcShwneP6ytpiIi5fQ" class="mr-2" rel="nofollow"><img
                                src="@/assets/img/social/yt.png" alt="yt"></a>
                        </div>
                        <div class="flex">
                            <div class="w-full">
                                <div class="swiper-container mySwiper2">
                                    <div class="swiper-wrapper">

                                    </div>
                                    <div class="swiper-pagination pagination2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:w-full xl:w-5/12">
                        <h5>Навигация по сайту</h5>
                        <ul>
                            <li v-for="(item, idx) in menu" :key="'menu-botton-'+idx">
                                <a :href="item.link" v-if="item.outer" target="_blank" rel="nofollow">{{item.name}}</a>
                                <router-link v-else tag="a" :to="item.link">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="sm:w-full xl:w-1/3">

                        <SubscribeNews></SubscribeNews>

                        <h6>Телефон доверия для работников</h6>
                        <p class="small-white2">Оказание психологической, моральной и духовной поддержки:</p>
                        <h6 class="mb-6 inline-flex"><img src="@/assets/img/icons/tel.svg" alt="Белтаможсервис"><a
                            href="tel:80333335848">8 (033) 333 58 48</a></h6>
                        <h6>Юридический адрес:</h6>

                        <p class="small-white2">222210, Минская область, Смолевичский район, Китайско-Белорусский индустриальный парк «Великий камень», проспект Пекинский, 18.</p>

                        <h6>Реквизиты:</h6>

                        <p class="small-white2">р/с № BY83AKBB30120000084320000000 в ОАО «АСБ Беларусбанк», код
                            AKBBBY2X, адрес банка: г. Минск, пр-т Дзержинского, 18 ОКПО 37529913 УНП 101561144</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>

</template>

<script>
import SubscribeNews from "../subscribe/News";
import SwiperPartners from "../sliders/SwiperPartners"
import Menu from "../../menu/menu-bottom";

export default {
    name: "Footer",
    components: {
        SubscribeNews, SwiperPartners
    },
    data: () => ({
        menu: []
    }),
    created() {
        this.menu = Menu
    }
}
</script>

<style scoped>

.logo2 {
    height: 70px;
    margin-bottom: 30px;
    margin-right: 10px;
}

.small-white {
    color: #fff;
    font-size: 10px;
    margin-bottom: 25px;
}

.small-white2 {
    color: #fff;
    font-size: 13px;
    margin-bottom: 15px;
}

footer .swiper-container {
    padding-bottom: 30px;
}

.pagination2 {
    bottom: 0 !important;
}

footer .swiper-pagination-bullet-active {
    background-color: #fff;
}

h5 {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;
}

footer ul {
    columns: 2;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 25px;
}

footer li {
    margin-bottom: 7px;
}

footer li a {
    color: #fff;
    font-size: 14px;
    transition: all .3s ease;
    font-weight: 400;
}

footer li a:hover {
    color: #58E3F5;
}

h6 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
}

h6 a {
    color: #fff;
    transition: all .3s ease;
}

h6 a:hover {
    color: #58E3F5;
}

h6 img {
    margin-right: 10px;
}
</style>